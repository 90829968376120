import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { auth, db } from '../firebase';
import { signOut } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import './Navbar.css';

const Navbar = ({ user, isHomepage }) => {
  const navigate = useNavigate();
  const [managedTeamId, setManagedTeamId] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const fetchManagedTeam = async () => {
      if (!user) return;

      try {
        const teamsQuery = query(
          collection(db, 'teams'),
          where('managerId', '==', user.uid)
        );
        const teamsSnapshot = await getDocs(teamsQuery);

        if (!teamsSnapshot.empty) {
          const managedTeam = teamsSnapshot.docs[0];
          setManagedTeamId(managedTeam.id);
        }
      } catch (error) {
        console.error('Error fetching managed team:', error);
      }
    };

    fetchManagedTeam();
  }, [user]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen((prev) => !prev);
  };

  // 🔹 Determine where the navbar brand should link
  const brandLink = user ? "/dashboard" : "/";

  if (isHomepage) {
    // Minimal Navbar for the homepage
    return (
      <nav className="navbar">
        <div className="navbar-header">
          <Link to={brandLink} className="navbar-brand">
            <img src="/favicon.svg" alt="fiveaside.app" />
          </Link>
          <Link to="/login" className="cta-button primary">Sign in</Link>
        </div>
      </nav>
    );
  }

  // Full Navbar for other pages
  return (
    <nav className="navbar">
      <div className="navbar-header">
        <Link to={brandLink} className="navbar-brand">
          <img src="/favicon.svg" alt="fiveaside.app" />
        </Link>
        <button className="burger-menu" onClick={toggleMenu}>
          ☰
        </button>
      </div>
      {isMenuOpen && (
        <div className="navbar-links">
          {user ? (
            <>
              <Link to="/dashboard" className="navbar-link" onClick={toggleMenu}>Dashboard</Link>
              <Link to="/team-registration" className="navbar-link" onClick={toggleMenu}>Register Team</Link>
              <Link to="/join-team" className="navbar-link" onClick={toggleMenu}>Join Team</Link>
              <Link to="/create-game" className="navbar-link" onClick={toggleMenu}>Create Game</Link>
              {managedTeamId && (
                <Link to={`/team-management/${managedTeamId}`} className="navbar-link" onClick={toggleMenu}>Team Management</Link>
              )}

              {/* 🔹 Profile Page Link */}
              <Link to="/profile" className="navbar-link" onClick={toggleMenu}>Profile</Link>

              <button onClick={() => { handleLogout(); toggleMenu(); }} className="navbar-link logout-btn">
                Logout
              </button>
            </>
          ) : (
            <>
              <Link to="/login" className="navbar-link" onClick={toggleMenu}>Login</Link>
              <Link to="/register" className="navbar-link" onClick={toggleMenu}>Register</Link>
            </>
          )}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
