import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css'; // For custom styling (optional)

const Home = () => {
  return (
    <div className="home-container">
      <header className="hero-section">
        <div className="hero-image-container" style={{ backgroundImage: "url('/images/homepage-hero.png')" }}>
        </div>
        <h1>Free, simple, football team organiser</h1>
        <p>
          Create a team, invite your mates, arrange games and start kicking balls.
        </p>
        <div className="cta-buttons">
          <Link to="/register" className="cta-button primary full-width">Get organised</Link>
        </div>
      </header>
      <section className="features-section">
        <h2>Why Choose fiveaside.app?</h2>
        <div className="features">
          <div className="feature">
            <h3>Organize Games Effortlessly</h3>
            <p>Create matches, invite players, and manage your team all in one place.</p>
          </div>
          <div className="feature">
            <h3>Team Management</h3>
            <p>Keep track of your team’s members, positions, and performance seamlessly.</p>
          </div>
          <div className="feature">
            <h3>Join and Compete</h3>
            <p>Search for teams near you, send join requests, and start playing in minutes.</p>
          </div>
        </div>
      </section>
      <footer className="footer">
        <p>&copy; {new Date().getFullYear()} fiveaside.app. All rights reserved.</p>
        <p>
          <Link to="/about">About Us</Link> | <Link to="/contact">Contact</Link>
        </p>
      </footer>
    </div>
  );
};

export default Home;
