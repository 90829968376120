import React, { useState } from 'react';
import { auth, db } from '../firebase';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { collection, query, where, getDocs, setDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const CLOUDINARY_URL = 'https://api.cloudinary.com/v1_1/dbsrsfkpx/image/upload';
const CLOUDINARY_UPLOAD_PRESET = 'fiveasideapp';

const Signin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [position, setPosition] = useState('');
  const [skillLevel, setSkillLevel] = useState(1);
  const [profilePicture, setProfilePicture] = useState(null);
  const [existingUser, setExistingUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const checkEmailExists = async (email) => {
    try {
      const playersQuery = query(collection(db, 'players'), where('email', '==', email));
      const snapshot = await getDocs(playersQuery);

      return !snapshot.empty;
    } catch (err) {
      console.error('Error checking email existence:', err);
      throw new Error('Failed to check email. Please try again.');
    }
  };

  const handleCheckEmail = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const exists = await checkEmailExists(email);
      setExistingUser(exists);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const uploadImageToCloudinary = async (image) => {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);

    try {
      const response = await axios.post(CLOUDINARY_URL, formData);
      return response.data.secure_url;
    } catch (err) {
      console.error('Error uploading image:', err);
      return null;
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      let profilePictureURL = '';
      if (profilePicture) {
        profilePictureURL = await uploadImageToCloudinary(profilePicture);
      }

      await setDoc(doc(db, 'players', user.uid), {
        uid: user.uid,
        email: email,
        name: name,
        position: position,
        skillLevel: skillLevel,
        profilePictureURL: profilePictureURL,
      });

      navigate('/dashboard');
    } catch (err) {
      console.error('Error registering user:', err);
      setError('Failed to register. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    setLoading(true);

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard');
    } catch (err) {
      console.error('Error logging in:', err);
      setError('Failed to log in. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>{existingUser ? 'Login' : 'Register'}</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={existingUser ? handleLogin : handleRegister}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="button" onClick={handleCheckEmail} disabled={loading}>
          Check Email
        </button>
        {existingUser && (
          <>
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </>
        )}
        {!existingUser && (
          <>
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Position"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
            />
            <input
              type="number"
              placeholder="Skill Level (1-5)"
              value={skillLevel}
              onChange={(e) => setSkillLevel(Number(e.target.value))}
            />
            <input
              type="file"
              accept="image/*"
              onChange={(e) => setProfilePicture(e.target.files[0])}
            />
          </>
        )}
        <button type="submit" disabled={loading}>
          {loading ? 'Processing...' : existingUser ? 'Login' : 'Register'}
        </button>
      </form>
    </div>
  );
};

export default Signin;
