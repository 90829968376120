import React, { useState } from 'react';
import { auth, db } from '../firebase';
import { doc, setDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const CompleteProfile = () => {
  const user = auth.currentUser;
  const navigate = useNavigate();
  const [name, setName] = useState(user?.displayName || "");
  const [position, setPosition] = useState("");
  const [skillLevel, setSkillLevel] = useState(1);
  const [error, setError] = useState("");

  const handleCompleteProfile = async (e) => {
    e.preventDefault();

    try {
      await setDoc(doc(db, 'players', user.uid), {
        uid: user.uid,
        name: name || user.displayName,
        email: user.email,
        position: position || 'Unknown',
        skillLevel: skillLevel || 1,
        isTeamManager: false,
        profilePictureURL: user.photoURL || ''
      });

      navigate('/dashboard'); // ✅ Now allow them to access dashboard
    } catch (error) {
      console.error("Error completing profile:", error);
      setError("Failed to complete profile. Try again.");
    }
  };

  return (
    <div>
      <h2>Complete Your Profile</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <form onSubmit={handleCompleteProfile}>
        <input
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="text"
          placeholder="Position"
          value={position}
          onChange={(e) => setPosition(e.target.value)}
        />
        <input
          type="number"
          placeholder="Skill Level (1-5)"
          value={skillLevel}
          onChange={(e) => setSkillLevel(Number(e.target.value))}
        />
        <button type="submit">Save Profile</button>
      </form>
    </div>
  );
};

export default CompleteProfile;
