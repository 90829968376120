import React, { useState } from 'react';
import { auth, db } from '../firebase';
import { 
  GoogleAuthProvider, 
  signInWithPopup, 
  signInWithEmailAndPassword, 
  multiFactor, 
  PhoneAuthProvider, 
  RecaptchaVerifier 
} from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { useNavigate, Link } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [resolver, setResolver] = useState(null);
  const [phoneVerificationId, setPhoneVerificationId] = useState(null);
  const navigate = useNavigate();

  // 🔹 Google Sign-In with Profile Check
  const handleGoogleSignIn = async () => {
    setError('');
    const provider = new GoogleAuthProvider();
    
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
  
      // ✅ Ensure Firebase Auth has fully recognized the user before checking Firestore
      await new Promise((resolve) => setTimeout(resolve, 500)); 
  
      // 🔍 Check if user already exists in Firestore
      const userDocRef = doc(db, 'players', user.uid);
      const userSnapshot = await getDoc(userDocRef);
  
      if (!userSnapshot.exists()) {
        // 🚀 New user → Redirect to profile completion page
        navigate('/complete-profile');
      } else {
        navigate('/dashboard'); // Existing user, go to dashboard
      }
    } catch (error) {
      console.error("Google Sign-In error:", error);
      setError("Google Sign-In failed. Try again.");
    }
  };

  // 🔹 Email/Password Login (Including 2FA)
  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      if (multiFactor(user).enrolledFactors.length > 0) {
        const resolver = multiFactor(user).resolver;
        const phoneAuthOption = resolver.hints.find(hint => hint.factorId === PhoneAuthProvider.FACTOR_ID);

        if (phoneAuthOption) {
          setResolver(resolver);
          sendVerificationCode(phoneAuthOption);
          return;
        }
      }

      navigate('/dashboard');
    } catch (error) {
      console.error("Login error:", error);
      setError("Failed to log in. Please check your credentials.");
    }
  };

  // 🔹 Send 2FA Verification Code
  const sendVerificationCode = async (phoneAuthOption) => {
    try {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'invisible'
      });

      const provider = new PhoneAuthProvider(auth);
      const verificationId = await provider.verifyPhoneNumber(phoneAuthOption.uid, window.recaptchaVerifier);
      setPhoneVerificationId(verificationId);
    } catch (error) {
      console.error("Error sending verification code:", error);
      setError("Failed to send verification code.");
    }
  };

  // 🔹 Verify 2FA Code
  const verifyCode = async () => {
    try {
      const cred = PhoneAuthProvider.credential(phoneVerificationId, verificationCode);
      const multiFactorAssertion = multiFactor(auth.currentUser).assertion(cred);
      await resolver.resolveSignIn(multiFactorAssertion);

      navigate('/dashboard');
    } catch (error) {
      console.error("Verification error:", error);
      setError("Invalid verification code. Please try again.");
    }
  };

  return (
    <div>
      <h2>Login</h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {!resolver ? (
        <div>
          <form onSubmit={handleLogin}>
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <button type="submit">Login</button>
          </form>

          {/* 🔹 Google Sign-In Button */}
          <button onClick={handleGoogleSignIn}>Sign in with Google</button>
        </div>
      ) : (
        <div>
          <h3>Enter 2FA Code</h3>
          <input
            type="text"
            placeholder="Enter verification code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
          <button onClick={verifyCode}>Verify</button>
        </div>
      )}

      <div id="recaptcha-container"></div>

      <p>
        Don't have an account? <Link to="/register">Register here</Link>
      </p>
    </div>
  );
};

export default Login;
