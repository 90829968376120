import React, { useState, useEffect } from 'react';
import { auth, db, storage } from '../firebase'; // Ensure storage is properly imported
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import './Profile.css';

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [editingField, setEditingField] = useState(null);
  const [newName, setNewName] = useState('');
  const [newSkillLevel, setNewSkillLevel] = useState('');
  const [loading, setLoading] = useState(true);
  const [uploading, setUploading] = useState(false);

  const user = auth.currentUser;

  useEffect(() => {
    if (!user) return;

    const fetchUserProfile = async () => {
      const userDocRef = doc(db, 'players', user.uid);
      const userSnap = await getDoc(userDocRef);

      if (userSnap.exists()) {
        const data = userSnap.data();
        setUserData(data);
        setNewName(data.name || '');
        setNewSkillLevel(data.skillLevel || '');
      }
      setLoading(false);
    };

    fetchUserProfile();
  }, [user]);

  const handleProfilePictureChange = async (e) => {
    if (!user || !e.target.files[0]) return;

    const file = e.target.files[0];
    const storageRef = ref(storage, `profile_pictures/${user.uid}`);

    try {
      setUploading(true);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);

      const userDocRef = doc(db, 'players', user.uid);
      await updateDoc(userDocRef, { profilePictureURL: downloadURL });

      setUserData((prev) => ({ ...prev, profilePictureURL: downloadURL }));
    } catch (error) {
      console.error('Error updating profile picture:', error);
    } finally {
      setUploading(false);
    }
  };

  const handleSaveField = async (field) => {
    if (!user) return;

    const userDocRef = doc(db, 'players', user.uid);
    let updatedData = {};

    if (field === 'name' && newName.trim()) {
      updatedData.name = newName.trim();
    } else if (field === 'skillLevel' && newSkillLevel) {
      const skill = parseInt(newSkillLevel, 10);
      if (skill < 1 || skill > 5) {
        alert("Skill level must be between 1 and 5");
        return;
      }
      updatedData.skillLevel = skill;
    }

    try {
      await updateDoc(userDocRef, updatedData);
      setUserData((prev) => ({ ...prev, ...updatedData }));
      setEditingField(null);
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  if (loading) return <p>Loading profile...</p>;

  return (
    <div className="profile-container">
      <h2>My Profile</h2>

      {/* Profile Picture Upload */}
      <div className="profile-picture">
        <label htmlFor="profile-pic-input">
          <img
            src={userData?.profilePictureURL || '/default-avatar.png'}
            alt="Profile"
            className="profile-pic"
          />
        </label>
        <input
          id="profile-pic-input"
          type="file"
          accept="image/*"
          onChange={handleProfilePictureChange}
          style={{ display: 'none' }}
        />
        {uploading && <p>Uploading...</p>}
      </div>

      {/* Display Name Edit */}
      <div className="profile-field">
        <label>Display Name:</label>
        {editingField === 'name' ? (
          <>
            <input
              type="text"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
            <button onClick={() => handleSaveField('name')}>Save</button>
          </>
        ) : (
          <p onClick={() => setEditingField('name')} className="editable">
            {userData?.name || 'Unknown'}
          </p>
        )}
      </div>

      {/* Skill Level Edit */}
      <div className="profile-field">
        <label>Skill Level:</label>
        {editingField === 'skillLevel' ? (
          <>
            <input
              type="number"
              min="1"
              max="5"
              value={newSkillLevel}
              onChange={(e) => setNewSkillLevel(e.target.value)}
            />
            <button onClick={() => handleSaveField('skillLevel')}>Save</button>
          </>
        ) : (
          <p onClick={() => setEditingField('skillLevel')} className="editable">
            {userData?.skillLevel || 'Not set'}
          </p>
        )}
      </div>
    </div>
  );
};

export default Profile;
