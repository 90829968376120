import React, { useState, useRef, useEffect } from 'react';
import { auth, db } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import axios from 'axios';
import './RegisterPlayer.css';

const CLOUDINARY_URL = 'https://api.cloudinary.com/v1_1/dbsrsfkpx/image/upload';
const CLOUDINARY_UPLOAD_PRESET = 'fiveasideapp';

const RegisterPlayer = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [position, setPosition] = useState('');
  const [skillLevel, setSkillLevel] = useState(1);
  const [profilePicture, setProfilePicture] = useState(null);
  const [error, setError] = useState('');

  // Refs for manual focus switching
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const positionRef = useRef(null);
  const skillLevelRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }, []);

  const uploadImageToCloudinary = async (image) => {
    const formData = new FormData();
    formData.append('file', image);
    formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);

    try {
      const response = await axios.post(CLOUDINARY_URL, formData);
      return response.data.secure_url;
    } catch (err) {
      console.error('Error uploading image:', err);
      return null;
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError('');

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      let profilePictureURL = '';
      if (profilePicture) {
        profilePictureURL = await uploadImageToCloudinary(profilePicture);
      }
  
      await setDoc(doc(db, 'players', user.uid), {
        uid: user.uid,
        name: name,
        position: position,
        skillLevel: skillLevel,
        isTeamManager: false,
        profilePictureURL: profilePictureURL
      });

      window.location.href = '/dashboard';
    } catch (error) {
      console.error('Error registering player:', error);
      setError('Failed to register. Please check your details and try again.');
    }
  };

  // Function to handle Enter key and move to next field
  const handleKeyDown = (e, nextFieldRef) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (nextFieldRef && nextFieldRef.current) {
        nextFieldRef.current.focus();
      }
    }
  };

  return (
    <div className="register-container">
      <h2>Player Registration</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleRegister}>
        
        <label>
          Your Full Name
          <input
            type="text"
            placeholder="Enter your full name"
            value={name}
            ref={nameRef}
            onChange={(e) => setName(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, emailRef)}
            enterKeyHint="next"
            autoComplete="name"
          />
        </label>

        <label>
          Email Address
          <input
            type="email"
            inputMode="email"
            placeholder="Enter your email"
            value={email}
            ref={emailRef}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, passwordRef)}
            enterKeyHint="next"
            autoComplete="email"
          />
        </label>

        <label>
          Create a Password
          <input
            type="password"
            placeholder="Enter a strong password"
            value={password}
            ref={passwordRef}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, positionRef)}
            enterKeyHint="next"
            autoComplete="new-password"
          />
        </label>

        <label>
          Preferred Position
          <input
            type="text"
            placeholder="e.g. Striker, Defender, Midfielder"
            value={position}
            ref={positionRef}
            onChange={(e) => setPosition(e.target.value)}
            onKeyDown={(e) => handleKeyDown(e, skillLevelRef)}
            enterKeyHint="next"
            autoComplete="off"
          />
        </label>

        <label>
          Skill Level (1-5)
          <input
            type="number"
            inputMode="numeric"
            min="1"
            max="5"
            placeholder="Rate yourself (1 = Beginner, 5 = Pro)"
            value={skillLevel}
            ref={skillLevelRef}
            onChange={(e) => setSkillLevel(Number(e.target.value))}
            onKeyDown={(e) => handleKeyDown(e, fileInputRef)}
            enterKeyHint="next"
            autoComplete="off"
          />
        </label>

        <label>
          Upload a Profile Picture
          <input
            type="file"
            accept="image/*"
            ref={fileInputRef}
            onChange={(e) => setProfilePicture(e.target.files[0])}
            enterKeyHint="done"
            autoComplete="off"
          />
        </label>

        <button type="submit" className="register-button">Register</button>
      </form>
    </div>
  );
};

export default RegisterPlayer;
