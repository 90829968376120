import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import RegisterPlayer from './components/RegisterPlayer';
import Signin from './components/Signin';
import TeamRegistration from './components/TeamRegistration';
import JoinTeam from './components/JoinTeam';
import Dashboard from './components/Dashboard';
import CreateGame from './components/CreateGame';
import EditGame from './components/EditGame';
import TeamManagement from './components/TeamManagement';
import Navbar from './components/Navbar';
import CompleteProfile from './components/CompleteProfile';
import Profile from './components/Profile';
import { auth, db } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import './App.css';

const App = () => {
  const [user, setUser] = useState(null);
  const [authChecked, setAuthChecked] = useState(false);
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);

      if (currentUser) {
        const userDocRef = doc(db, 'players', currentUser.uid);
        const userSnapshot = await getDoc(userDocRef);

        if (userSnapshot.exists()) {
          setUserProfile(userSnapshot.data());
        } else {
          setUserProfile(null);
        }
      } else {
        setUserProfile(null);
      }

      setAuthChecked(true);
    });

    return () => unsubscribe();
  }, []);

  if (!authChecked) {
    return (
      <div className="preloader-container fade-in">
        <div className="preloader">
          <div className="loader"></div>
        </div>
      </div>
    );
  }

  return (
    <Router>
      <ConditionalNavbar user={user} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={user ? <Navigate to="/dashboard" /> : <Login />} />
        <Route path="/register" element={user ? <Navigate to="/dashboard" /> : <RegisterPlayer />} />
        <Route path="/signin" element={<Signin />} />
        <Route path="/complete-profile" element={user ? <CompleteProfile /> : <Navigate to="/signin" />} />
        <Route path="/profile" element={user ? <Profile /> : <Navigate to="/signin" />} />

        {/* 🔒 Protected Routes (Require Authentication & Profile) */}
        {user && userProfile ? (
          <>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/team-registration" element={<TeamRegistration />} />
            <Route path="/join-team" element={<JoinTeam />} />
            <Route path="/create-game" element={<CreateGame />} />
            <Route path="/edit-game/:gameId" element={<EditGame />} />
            <Route path="/team-management/:teamId" element={<TeamManagement />} />
          </>
        ) : (
          <>
            <Route path="/dashboard" element={<Navigate to="/complete-profile" />} />
            <Route path="/team-registration" element={<Navigate to="/complete-profile" />} />
            <Route path="/join-team" element={<Navigate to="/complete-profile" />} />
            <Route path="/create-game" element={<Navigate to="/complete-profile" />} />
            <Route path="/edit-game/:gameId" element={<Navigate to="/complete-profile" />} />
            <Route path="/team-management/:teamId" element={<Navigate to="/complete-profile" />} />
          </>
        )}
      </Routes>
    </Router>
  );
};

// Conditional Navbar Component
const ConditionalNavbar = ({ user }) => {
  const location = useLocation();
  return <Navbar user={user} isHomepage={location.pathname === '/'} />;
};

export default App;
